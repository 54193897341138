
<template>
  <div class="info-section eoi-benefit-section">
    <div class="info-wrapper">
      <div class="row m-0 w-100">
        <div class="heading-box">
          <h2 class="main-heading">Benefits of <span> EOI</span></h2>
        </div>
        <div class="col-md-12 amenities-section eoi-highlights-section">
          <div class="row">
            <div class="col-6 col-lg-4 eoi-point">
              <img src="@/assets/images/EOIBenefits/refundable.svg" height="30" width="30" alt="Refundable" class="img-fluid" />
              <span class="eoi-point-text">100% <br />Refundable</span>
            </div>
            <div class="col-6 col-lg-4 eoi-point">
              <img src="@/assets/images/EOIBenefits/inventory.svg" height="30" width="30" alt="Early Access" class="img-fluid" />
              <span class="eoi-point-text">Early Access to Premium Inventory</span>
            </div>
            <div class="col-6 col-lg-4 eoi-point">
              <img src="@/assets/images/EOIBenefits/savings.svg" height="30" width="30" alt="Savings" class="img-fluid" />
              <span class="eoi-point-text">Big <br />on Savings</span>
            </div>
            <div class="col-6 col-lg-4 eoi-point">
              <img src="@/assets/images/EOIBenefits/decision.svg" height="30" width="30" alt="Informed Decision" class="img-fluid" />
              <span class="eoi-point-text">Allows You to Make an Informed Decision</span>
            </div>
            <div class="col-6 col-lg-4 eoi-point">
              <img src="@/assets/images/EOIBenefits/prelaunch.svg" height="30" width="30" alt="Pre launch rate advantage" class="img-fluid" />
              <span class="eoi-point-text">Pre-Launch Rate Advantage</span>
            </div>
            <div class="col-6 col-lg-4 eoi-point">
              <img src="@/assets/images/EOIBenefits/loanapproval.svg" height="30" width="30" alt="Refundable" class="img-fluid" />
              <span class="eoi-point-text">Adequate Time for Loan Approval</span>
            </div>
          </div>
          <div class="row eoi-bottom-section">
            <span class="eoi-bottom"><span class="eoi-bold">E.O.I.</span><span class="eoi-text-gradient"> The Most Secure Way of Reserving Your Dream Home</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  computed: {
    isMobile() {
      return window && window.innerWidth <=991;
    }
  }
}
</script>
<style type="text/css" scoped>
.info-wrapper .heading-box {
  width: 100%;
  padding: 12px 16px;
  background: rgba(2, 103, 193, 0.1);
  border-radius: 10px 10px 0px 0px;
}

.key-highlights {
  width: 30px;
  height: auto;
  padding: 8px;
}

.info-wrapper .heading-box h2,
.amenities-section h2 {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #494899;
  opacity: 1;
}

.heading-box h2 span {
  font-weight: 600;
  padding-left: 3px;
}

.info-section.eoi-benefit-section {
  margin-top: 4px;
  margin-bottom: 4px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FBF7FA 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}

.info-section.eoi-benefit-section .heading-box {
  border-radius: 10px 10px 0px 0px;
  color: #2B2D42;
}

.eoi-benefit-section .info-wrapper .heading-box h2 {
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  color: #2B2D42;
}

.eoi-highlights-section {
  position: relative;
}

.eoi-highlights-section .eoi-bottom-img {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 0;
}

.eoi-highlights-section .eoi-point {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  margin-top: 10px;
  z-index: 1;
}

.eoi-highlights-section .eoi-point img {
  margin: 6px;
}

.eoi-highlights-section .eoi-bottom {
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  padding: 12px 6px;
  width: 100%;
  z-index: 1;
  background-color: #2B2D42;
  border-radius: 0px 0px 10px 10px;
}


.eoi-highlights-section .eoi-bottom .eoi-bold {
  font-weight: 700;
  color: #ffffff;
}

.eoi-highlights-section .eoi-bottom .eoi-text-gradient {
  color: #ffffff;
}

@media (max-width:991px){
  .info-wrapper .heading-box {
    border-radius: 0;
  }
  .info-section.eoi-benefit-section .heading-box{
    border-radius: 0;
  }
  .info-section.eoi-benefit-section{
    border-radius: 0;
  }
  .eoi-bottom{
    width: 100%;
  }
  .eoi-highlights-section .eoi-bottom-img{
    width: 100%;
  }
  .eoi-bottom-section{
    margin-top: 80px;
  }
}

@media (min-width:1165px) and (max-width:1334px){
  .eoi-highlights-section .eoi-bottom{
    font-size: 10px;
  }
}

@media (min-width:1080px) and (max-width:1164px){
  .eoi-highlights-section .eoi-bottom{
    font-size: 9px;
  }
}

@media (min-width:992px) and (max-width:1079px){
  .eoi-highlights-section .eoi-bottom{
    font-size: 7px;
  }
}

@media (max-width:767px){
  .eoi-bottom-section{
    margin-top: 60px;
  }
}
@media (max-width:700px){
  .eoi-bottom-section{
    margin-top: 40px;
  }
}

@media (max-width:600px){
  .eoi-bottom-section{
    margin-top: 20px;
  }
}
@media (max-width:390px){
  .eoi-highlights-section .eoi-bottom{
    font-size: 10px;
  }
}
@media (max-width:330px){
  .eoi-highlights-section .eoi-bottom{
    font-size: 8px;
  }
}
</style>